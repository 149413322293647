$(function() {
    AOS.init();

    $('.nested__href').click( function(e){
        e.preventDefault();
        $(this).toggleClass('is-active');
        $('.nested-nav-wrapper').removeClass('is-active');
        $('.nested__href').removeClass('is-active')
        $(this).next('.nested-nav-wrapper').toggleClass('is-active');

            if($('.nested-nav-wrapper').hasClass('is-active')){
                $(this).addClass('is-active');
            }
    })

    $(document).click(function(evt){    
        if(!$(evt.target).is('.nested__href')) {
            $('.nested-nav-wrapper').removeClass('is-active');

            if($('.main-nav__wrapper').find('a').not('nested__href').hasClass('is-active')){
                $('.nested__href').removeClass('is-active');
            }
        }
    });

    // hamburger
    $('#hamburger').click(function(){
        $(this).toggleClass('open');
        $('.main-nav').toggleClass('open');
    });

    sierotki();
    

    // FAQ LISTA
    if (window.location.href.indexOf("pytania") > -1) {
        $('.faq-container__wrapper nav li').click(function() {
            const el = $(this);
            const show =  el.attr('show');
            const scrollToEl = el.attr('toScroll');
            
            // nadanie active w liscie
            $('.faq-container__wrapper nav li span').removeClass('is-active');
            el.find('span').addClass('is-active');

            // pokazywanie containera
            $('.faq-container__content').addClass('hide');
            $(`#${show}`).removeClass('hide');

            // nadanie active tytułowi
            // $('.faq-container__content--item h3').removeClass('is-active');
            // $('.faq-container__content').not('hide').find(`[elScroll='${scrollToEl}']`).find('h3').addClass('is-active');

            // scroll 
            setTimeout(() => {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $('.faq-container__content').not('.hide').find(`[elScroll='${scrollToEl}']`).offset().top - 30
                }, 500);
                console.log($(`[elScroll='${scrollToEl}']`).offset().top)
            }, 0);
        })

    // FAQ NAWIGACJA SCROLL
  
        const stickyHeaderTop = $('.faq-container__nav').offset().top;


        if ($(window).width() > 769) {
      
            $(window).scroll(function(){
                if( $(window).scrollTop() > stickyHeaderTop ) {
                    $('.faq-container__nav').css({position: 'fixed', top: '20px'});
                    $('.faq-container__nav').removeClass('is-nav-relative')
                    $('.faq-container__content').addClass('is-nav-fixed')
        
                    if($('.faq-container__nav').offset().top + $('.faq-container__nav').height() > $('#footer-event').offset().top - 30) {
                        console.log('wchodzi')
                        $('.faq-container__nav').addClass('is-nav-relative')
                        $('.faq-container__content').removeClass('is-nav-fixed')
                    } 
                    // else {
                    //     // $('.faq-container__nav').css({position: 'fixed', top: '20px'});
                    //     $('.faq-container__nav').removeClass('is-nav-relative')
                    // }
                } else {
                    $('.faq-container__nav').css({position: 'static', top: '0px'});
                    $('.faq-container__content').removeClass('is-nav-fixed')
                    $('.faq-container__content').removeClass('is-nav-relative')
                }
            });
        }
    }


    // validacja 
    $("input[name='your-count']").keypress(function(event) {
        return isNumberKey(event)
    })

    $("input[name='your-day']").keypress(function(event) {
        return isNumberKey(event)
    })

    $("input[name='your-hour']").keypress(function(event) {
        return isNumberKey(event)
    })

    $("input[name='your-phone']").keypress(function(event) {
        return isNumberKey(event)
    })

    // const cleaveDay = new Cleave($("input[name='your-day']"), {
    //     blocks: [2, 2, 4],
    //     delimiter: '.',
    // });

    // const cleaveHour = new Cleave($("input[name='your-hour']"), {
    //     blocks: [2, 2],
    //     delimiter: ':',
    // });

    // const cleaveNumber = new Cleave($("input[name='your-phone']"), {
    //     blocks: [3, 3, 3],
    //     delimiter: ' ',
    // });

    initMap()

});

function sierotki(){
    $('section p, section span, h1, h2, h3, h4, h5, h6, p, span').each(function(){
        if($(this).length > 0) {
        var $html = $(this).html();
        $html = $html.replace(/(\s)([\S])[\s]+/g, "$1$2&nbsp;");
            $(this).empty().html($html);
        } 
    });
}

function isNumberKey(evt){
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
    return true;
}


function initMap() {
    const myLatLng = {lat: 50.405, lng: 18.922};
    const icons = {
        info: {
            // icon:'tutaj link do pinu na serwerze'
        }
    };

    const map = new google.maps.Map(document.getElementById('map'), {
      zoom: 15,
      center: myLatLng,
      styles: [ { "featureType": "all", "elementType": "labels.text.fill", "stylers": [ { "saturation": "0" }, { "color": "#651932" }, { "lightness": "0" }, { "weight": "1.00" } ] }, { "featureType": "all", "elementType": "labels.text.stroke", "stylers": [ { "visibility": "on" }, { "color": "#ffffff" }, { "lightness": 16 } ] }, { "featureType": "all", "elementType": "labels.icon", "stylers": [ { "visibility": "off" } ] }, { "featureType": "administrative", "elementType": "geometry.fill", "stylers": [ { "color": "#000000" }, { "lightness": 20 } ] }, { "featureType": "administrative", "elementType": "geometry.stroke", "stylers": [ { "color": "#000000" }, { "lightness": 17 }, { "weight": 1.2 } ] }, { "featureType": "landscape", "elementType": "geometry", "stylers": [ { "color": "#f9f9f9" }, { "lightness": 20 } ] }, { "featureType": "poi", "elementType": "geometry", "stylers": [ { "color": "#e5e1e1" }, { "lightness": 21 } ] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [ { "color": "#e7e7e7" }, { "lightness": 17 } ] }, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [ { "color": "#bdbbbb" }, { "lightness": 29 }, { "weight": 0.2 } ] }, { "featureType": "road.arterial", "elementType": "geometry", "stylers": [ { "color": "#cdcdcd" }, { "lightness": 18 } ] }, { "featureType": "road.local", "elementType": "geometry", "stylers": [ { "color": "#e4e4e4" }, { "lightness": 16 } ] }, { "featureType": "transit", "elementType": "geometry", "stylers": [ { "color": "#a3a3a3" }, { "lightness": 19 } ] }, { "featureType": "water", "elementType": "geometry", "stylers": [ { "color": "#e7e7e7" }, { "lightness": 17 } ] } ]
    });
        
    const marker = new google.maps.Marker({
        position: myLatLng,
        map: map,
        title: 'Hello World!',
        // icon: icons['info'].icon,
    });

  }    