// swiper home
const swiperHome = new Swiper ('#home-slider-container', {
    // loop: true,
    autoplay: {
        delay: 4000,
    },

    watchOverflow: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },

    breakpoints: {
        769: {
            direction: 'vertical',
        }
    }
})

// swiper home events
const swiperEvents = new Swiper ('#home-slider-events-container', {
    // loop: true,

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
})

// swiper cards
const swiperCards = new Swiper ('.swiper-container-cards', {
    // loop: true,

    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    watchOverflow: true,
    breakpoints: {
        769: {
            direction: 'vertical',
        }
    }
})

const swiperComments = new Swiper ('.swiper-container-comment', {
    // loop: true,
    direction: 'horizontal',
    autoplay: {
        delay: 2000,
    },
    // watchOverflow: true,
    // breakpoints: {
    //     769: {
    //         direction: 'vertical',
    //     }
    // }
})

const swiperPhoto = new Swiper ('.swiper-container-photo', {
    // loop: true,
    direction: 'vertical',
    autoplay: {
        delay: 2000,
    },
    // watchOverflow: true,
    // breakpoints: {
    //     769: {
    //         direction: 'vertical',
    //     }
    // }
})



if($(".swiper-wrapper .swiper-slide").length == 1) {
    $('.swiper-pagination').hide();
}